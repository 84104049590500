import React, { useState } from "react";
import Main from "layouts/Main";
import Container from "components/Container";
import {
    Hero,
    Overview,
    Categories,
    MapHero,
    SearchBoxHero,
    AboutUs,
    ContactLanding
} from './components';
import Box from '@mui/material/Box';
import Timeline from './components/Timeline';
import OurMission from "./components/OurMission";
import Bateryjki from "./components/bateryjki";
import useProductData from "Hooks/useProductData";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const LandingPage = () => {
    const [searchInput, setSearchInput] = useState(null);
    const { filteredData, loading } = useProductData(null, null, null, searchInput);

    const handleSearchChange = (event, value) => {
        setSearchInput(value);
    };
    const { t } = useTranslation();

    return (
        <Main>
    
            <Box bgcolor={'alternate.main'} style={{marginTop: '-7.5vh'}}>
                <SearchBoxHero />
            </Box>
            <Container>
                <Categories id="categories-section" />
            </Container>
            <Box>
                <Container id="about-us">
                    <AboutUs />
                </Container>
            </Box>
            <Container>
                <MapHero />
            </ Container>
            <Container
                id="timeline-section"
                sx={{
                    display: {
                        xs: 'none',
                        sm : 'none',
                        lg: 'block'
                    },
                }}
            >
                <Timeline />
            </Container>
            <Box bgcolor={'primary.main'}>
                <Container bgcolor={'primary.main'} id="timeline-section">
                    <Bateryjki />
                </Container>
            </Box>
            <Container id="timeline-section">
                <OurMission />
            </Container>
            <Container id="contact-section">
                <ContactLanding />
            </Container>
        </Main>
    );
}

export default LandingPage;

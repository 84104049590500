// /src/components/Categories.js
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import CategoryTile from 'components/CategoryTile';
// import categories from 'config/categories';
import { useTranslation } from 'react-i18next';
import useCategoryGroups from 'Hooks/useCategoryGroups';
import useCategoryDetails from 'Hooks/useCategories';


const Categories = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const filters = useCategoryGroups();
  const categories = useCategoryDetails();
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);

  const renderCategories = () => {
    return categories
      .filter((category) => selectedFilter.ids.includes(category.id))
      .map((category) => <CategoryTile key={category.id} category={category} />);
  };

  const navigateToAll = () => {
    navigate(`/products/18,35,50,162,163,164,184,189,19,193,6,17,220,140,300,301,291,36,5,14,4,3,8,7,51`);
  };

  return (
    <Box>
      <Box marginBottom={4} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              // fontWeight: 'bold'
            }}
          >
            {t('categories.title')}
          </Typography>
        </Box>
        <Box>
          {filters.map((filter, index) => (
            <Link
              key={index}
              component="button"
              variant="body2"
              onClick={() => setSelectedFilter(filter)}
              sx={{
                textDecoration: 'none',
                marginRight: 2,
                color: filter.label === selectedFilter.label ? theme.palette.primary.main : theme.palette.text.secondary,
                fontWeight: filter.label === selectedFilter.label ? 'bold' : 'normal'
              }}
            >
              {t(`categories.filters.${filter.label}`)}
            </Link>
          ))}
        </Box>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {renderCategories()}
      </Grid>
      <Box marginTop={10} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" size="large" onClick={navigateToAll}>
          {t('categories.subtext_4')}
        </Button>
      </Box>
    </Box>
  );
};

export default Categories;

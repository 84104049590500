import { useState, useEffect } from "react";
import getTableApi from '../API/tableAPI/tableAPI';


const useCategoryGroups = (categoryName) => {
    // const [data, setData] = useState([]);

  //   useEffect(() => {
  //     async function fetchData() {
  //         let tableData = await getTableApi(categoryName);
  //         setData(tableData);
  //     }
  //     fetchData(); 
  // }, [categoryName]); 
  
  // return data;


  // MOCK MOCK MOCK
  const filters = [
    { label: 'trending', ids: [50, 18, 35] },
    { label: 'powerSources', ids: [50, 291, 300, 301, 51] },
    { label: 'capacitors', ids: [35, 140, 14, 36] },
    { label: 'semiconductors', ids: [162, 163, 164, 184, 5, 6, 7, 8, 3, 4, 193, 189, 17, 19] },
    { label: 'all', ids: [18, 35, 50, 162, 163, 164, 184, 189, 19, 193, 6, 17, 220, 140, 300, 301, 291, 36, 5, 14, 4, 3, 8, 7, 51] }
  ];
  

  return filters;
};
  
export default useCategoryGroups;
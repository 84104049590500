const pages = {
  main: [
    {
      title: 'topbar.pages.company.aboutUs',
      href: '/',
    },
    {
      title: 'topbar.pages.products',
      href: '/products',
    },
    {
      title: 'topbar.pages.support',
      id: 'support',
      items: [
        { title: 'topbar.pages.support.certificates', href: '/certificates' },
        // { title: 'topbar.pages.support.documentation', href: '/documentation' },
        { title: 'topbar.pages.support.whereToBuy', href: '/distributors' },
        // { title: 'topbar.pages.support.privacyPolicy', href: '/privacy-policy' },
      ],
    },
    {
      title: 'topbar.pages.company.contactUs',
      href: '/contact',
    },
  ],
};

export default pages;

import React from "react";
import {
  Box,
  Popover,
  Stack,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import FilterField from "./FilterField";

const FilterPopover = ({
  open,
  anchorEl,
  handleClose,
  filterGroup,
  onFilterChange,
  onApply,
  filteredData,
  filterValues,
}) => {
  const theme = useTheme();


  const getUniqueOptions = (data, columnName) => {
    const uniqueValues = new Set();
    data.forEach((item) => {
      if (item.params && item.params.Parameters && item.params.Parameters[columnName]) {
        uniqueValues.add(item.params.Parameters[columnName]);
      }
    });
    const uniqueArray = Array.from(uniqueValues);

    const isFloat = (value) => {
      const floatValue = parseFloat(value);
      return !isNaN(floatValue);
    };

    const sorted = uniqueArray.sort((a, b) => {
      if (isFloat(a) && isFloat(b)) {
        return parseFloat(a) - parseFloat(b);
      }
      return 0;
    });

    return sorted;
  };

  return (
    <Popover
      elevation={0}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        ".MuiPaper-root": {
          marginTop: 1,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 2,
        },
      }}
    >
      <Box padding={2}>
        <Typography variant="h6" gutterBottom>
          Enter {filterGroup.name}
        </Typography>
        {filterGroup.controls.map((control) => {
          const options =
            control.filterType === "checkboxlist"
              ? getUniqueOptions(filteredData, control.column)
              : [];
          return (
            <FilterField
              key={control.column}
              name={control.column}
              type={control.filterType}
              unit={control.basicUnit}
              options={options}
              value={filterValues[control.column]} // Ensure this is passed correctly
              onChange={onFilterChange}
              multipler={control.multipler}
            />
          );
        })}
      </Box>
      <Stack
        direction={"row"}
        spacing={2}
        padding={2}
        borderTop={`1px solid ${theme.palette.divider}`}
      >
        <Button
          fullWidth
          sx={{
            fontWeight: 700,
            color: "text.primary",
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color={"primary"}
          variant={"contained"}
          fullWidth
          onClick={onApply}
        >
          Apply
        </Button>
      </Stack>
    </Popover>
  );
};

export default FilterPopover;

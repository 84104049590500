import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterPopover from './FilterPopover';

const UberFilterGroup = ({ filteredData, filterGroup, selectedFilters, callback }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    const existingFilterValues = {};
    selectedFilters.forEach(filter => {
      const control = filterGroup.controls.find(control => control.column === filter.columnName.split('.').pop());
      if (control) {
        if (control.filterType === 'minmax') {
          if (!existingFilterValues[control.column]) {
            existingFilterValues[control.column] = {};
          }
          if (filter.operator === 'gte') {
            existingFilterValues[control.column].min = filter.value;
          } else if (filter.operator === 'lte') {
            existingFilterValues[control.column].max = filter.value;
          }
        } else {
          existingFilterValues[control.column] = filter.value;
        }
      }
    });
    setFilterValues(existingFilterValues);
  }, [selectedFilters, filterGroup]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleFilterChange = (name, value) => {
    setFilterValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const buildRequest = (filters) => {
    const request = [];

    Object.entries(filters).forEach(([key, value]) => {
      const control = filterGroup.controls.find(control => control.column === key.split(' ')[1] || control.column === key);

      if (control) {
        const columnName = `Parameters.${control.column}`;
        let operator = "eq";

        const multiplier = control.multipler ?? 1;
        value = Array.isArray(value) ? value.map(v => v * multiplier) : value * multiplier;

        if (control.filterType === 'checkboxlist') {
          request.push({
            columnName,
            value,
            operator: "in"
          });
        } else if (control.filterType === 'minmax') {
          if (key.startsWith('min')) {
            request.push({
              columnName,
              value,
              operator: "gte"
            });
          } else if (key.startsWith('max')) {
            request.push({
              columnName,
              value,
              operator: "lte"
            });
          }
          return; 
        } else {
          request.push({
            columnName,
            value,
            operator
          });
        }
      }
    });

    return request;
  };

  const handleApply = () => {
    const request = buildRequest(filterValues);
    callback && callback(request);
    handleClose();
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        border={`1px solid ${theme.palette.divider}`}
        borderRadius={2}
        paddingY={1}
        paddingX={2}
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Typography>{filterGroup.name}</Typography>
        <ExpandMoreIcon
          sx={{
            marginLeft: 0.5,
            width: 16,
            height: 16,
            transform: open ? 'rotate(180deg)' : 'none',
          }}
        />
      </Box>
      <FilterPopover
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        filterGroup={filterGroup}
        onFilterChange={handleFilterChange}
        onApply={handleApply}
        filteredData={filteredData}
        filterValues={filterValues}  // Pass the selected filter values to the popover
      />
    </Box>
  );
};

export default UberFilterGroup;

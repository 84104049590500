import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Orders = ({ basket, setBasket }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openRow, setOpenRow] = useState(null);

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const handleRemoveClick = (event, key) => {
    event.stopPropagation();
    const updatedBasket = { ...basket };
    delete updatedBasket[key];
    setBasket(updatedBasket);
    const basketEvent = new CustomEvent('basketChange');
    window.dispatchEvent(basketEvent);
    
    updateLocalStorage(updatedBasket);
  };

  const handleQuantityChange = (event, key) => {
    const updatedBasket = { ...basket };
    updatedBasket[key].quantity = parseInt(event.target.value) || 0;
    setBasket(updatedBasket);
    updateLocalStorage(updatedBasket);
  };

  const updateLocalStorage = (updatedBasket) => {
    localStorage.setItem('basket', JSON.stringify(updatedBasket));
  };

  const redirectToCatalog = () => {
    navigate('/products'); // Redirect to the catalog page
  };

  if (Object.keys(basket).length === 0) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="subtitle1" gutterBottom>
          Your basket is empty. Browse our catalog to find products.
        </Typography>
        <Button variant="contained" onClick={redirectToCatalog}>
          Browse Catalog
        </Button>
      </Box>
    );
  }

  return (
    <Box mt={4}>
      {Object.entries(basket).map(([key, item], index) => (
        <Box key={key}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ cursor: 'pointer', paddingY: 1 }}
          >
            <Box display={'flex'} alignItems={'center'} onClick={() => handleRowClick(index)} sx={{ flex: 1 }}>
              <Typography
                variant={'subtitle1'}
                sx={{ cursor: 'pointer' }}
              >
                {item.itemCode}
              </Typography>
              <IconButton size="small">
                {openRow === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <TextField
                label="Qty"
                type="number"
                value={item.quantity}
                onChange={(e) => handleQuantityChange(e, key)}
                sx={{ width: '80px', mr: 2 }}
                inputProps={{ style: { textAlign: 'center' } }}
              />
              <Tooltip title="Remove from basket">
                <IconButton size="small" onClick={(e) => handleRemoveClick(e, key)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Collapse in={openRow === index} timeout="auto" unmountOnExit>
            <Box marginY={1} paddingLeft={2}>
              <Typography variant={'subtitle2'}>Specification:</Typography>
              {Object.entries(item.details).map(([detailKey, value]) => (
                <Typography key={detailKey} variant={'body2'}>
                  <strong>{detailKey}:</strong> {value}
                </Typography>
              ))}
            </Box>
          </Collapse>
          <Divider />
        </Box>
      ))}
      <Box
        component={'form'}
        noValidate
        autoComplete="off"
        sx={{
          marginY: 4,
          '& .MuiInputBase-input.MuiOutlinedInput-input': {
            bgcolor: 'background.paper',
          },
        }}
      >
        {/* Discount form */}
      </Box>
      {/* Subtotal, Discount, VAT sections */}
      <Divider />
      {/* Single "Request Quote" button */}
    </Box>
  );
};

export default Orders;
